import React, { useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { onError } from "../libs/error";
import S3Image from "../components/S3Image";
import S3CarInfo from "../components/S3CarInfo";
import "./ViewCamera.css";
import dateFormat from 'dateformat';

export default function ViewCamera() {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * List contents of the bucket.
   */
  useEffect(onLoad, []);
  async function onLoad() {
    try {
      const now = new Date();
      const results = [];
      // TODO Make a date component
      await Storage.list('processed/' + dateFormat(now, "yyyymmdd"))
                   .then(result => result.filter(e => e.key.endsWith('.jpg'))
                                         .forEach(e => results.unshift(e))
                        )
                   .catch(err => onError(err));
      setEntries(results);
      setLoading(false);

    } catch(e) {
      onError(e);
    }
    console.log(entries);
  }


  /**
   * Return a timestamp from a bucket key.
   */
  function timestampFrom(key) {
    return key.replace(/(.+\/)?(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})-.*/, '$2-$3-$4 $5:$6:$7');
  }


  /**
   * Return a photo type from a bucket key.
   */
  function typeFrom(key) {
    return key.replace(/.*-(\w+)\.jpg/, '$1');
  }

  /**
   * Show list.
   */
  return (
    <div className="ViewCamera" >
      {loading ?
        <div className="loading">
          <h2>Loading...</h2>
        </div>
               : entries.map(item => (
        <div className="shot" key={item.key}>
          <p className="label">{timestampFrom(item.key)} <span className="type">{typeFrom(item.key)}</span> {item.key.endsWith('-car.jpg') ? <S3CarInfo object={item.key} /> : <></>}</p>
          <S3Image bucket={Storage._config.AWSS3.bucket} object={item.key} />
        </div>
      ))}
    </div>
  );
}
