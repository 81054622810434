import React, { useState } from "react";
import Img from 'react-image';
import { onError } from "../libs/error";
import { Storage } from "aws-amplify";
import spinner from './loading-1280x720.svg';
import VisibilitySensor from 'react-visibility-sensor';

export default function S3Image({
  bucket,
  object,
  ...props
}) {
  const [url, setUrl] = useState(null);

  /**
   * Get a URL on demand.
   */
  async function fetchUrl() {
    if (url === null) {
      try {
        console.log("loading " + object + " from " + bucket);
        await Storage.get(object, { expires: 120, download: false })
                    .then(result => setUrl(result))
                    .catch(err => onError(err));
      } catch(e) {
        onError(e);
      }
    }

    return url;
  }


  /**
   * Render the image
   */
  return (
    <VisibilitySensor onChange={fetchUrl}>
      <div className="S3Image">
        <Img src={url ? url : spinner}
             loader={<img src={spinner} alt="Loading..."/>}/>
      </div>
      </VisibilitySensor>
  );
}
