import stack from './aws-stack.js';

export default {
    Auth: {
        userPoolId: stack.UserPoolId,
        userPoolWebClientId: stack.UserPoolClientId,
        region: stack.IdentityPoolRegion,
        identityPoolId: stack.IdentityPoolId,
        identityPoolRegion: stack.IdentityPoolRegion,
        // mandatorySignIn: false,

        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: stack.UserPoolDomain + ".auth."+stack.IdentityPoolRegion+".amazoncognito.com",
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: window.location.protocol + "//" + window.location.host + "/",
          redirectSignOut: window.location.protocol + "//" + window.location.host + "/",
          responseType: 'token'
        }
    },
    API: {
      endpoints: [
        {
          name: "GateControl",
          endpoint: stack.ServiceEndpoint,
          region: stack.IdentityPoolRegion,
          header: {}
        },
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'gate-cam-prototype',
        region: stack.IdentityPoolRegion,
        customPrefix: {
          public: ''
        }
      }
    }
};
