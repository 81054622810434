import React, { useState } from "react";
// import { Button, Glyphicon } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/error";
import { API } from "aws-amplify";
import "./OpenGate.css";
import icon from "./OpenGate.svg";

export default function OpenGate() {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * When the form is submitted, call the gate to be opened.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await openGate('vehicle');
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  }


  /**
   * Open the gate.
   */
  async function openGate(type) {
    // return API.get("GateControl", "/ping")
    return API.post("GateControl", "/open/" + type)
  }


  /**
   * Render the component.
   */
  return (
    <div className="OpenGate">
      <form onSubmit={handleSubmit}>
        <LoaderButton
          type="submit"
          bsSize="large"
          isLoading={isLoading}
        ><br />
          <img src={icon} alt="Open Gate" />
        </LoaderButton>
      </form>
    </div>
  );
}
