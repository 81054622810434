import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/context";
import { onError } from "./libs/error";
import { Auth, Hub } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userId, setUserId] = useState("");

  /**
   * On loading the component, hook up with AWS Cognito.
   */
  useEffect(() => {
    onLoad();
  }, []);
  async function onLoad() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('sign in', event, data)
          userHasAuthenticated(true);
          break
        case 'signOut':
          console.log('sign out')
          userHasAuthenticated(false);
          break
        default:
          break
      }
    })

    try {
      await Auth.currentSession();
      Auth.currentUserInfo().then(user => {
        console.log("Current user = " + user.attributes.email)
        setUserId(user.attributes.email);
      });
      userHasAuthenticated(true);
    } catch(e) {
      // if (e !== 'No current user') {
      //   onError(e);
      // }
    }
  
    setIsAuthenticating(false);
  }


  /**
   * Handle logging a user out, including of AWS Cognito.
   */
  async function handleLogout() {
    await Auth.signOut();
  }
  

  /**
   * Main application container consists of a navigation bar and current route.
   */
  return (
    !isAuthenticating &&
    <div className="App container">
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">Gate Control</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {isAuthenticated && !userId.endsWith("@gate.invalid") &&
                <LinkContainer to="/camera">
                  <NavItem>Camera</NavItem>
                </LinkContainer>
            }
            {isAuthenticated ?
                <NavItem onClick={handleLogout}><span title={userId}>Logout</span></NavItem>
            :
              <>
                  <LinkContainer to="/signup">
                    <NavItem>Signup</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}



export default App;
