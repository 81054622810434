import React from "react";
import OpenGate from "../components/OpenGate";
import { useAppContext } from "../libs/context";
import "./Home.css";

export default function Home() {
    const {isAuthenticated} = useAppContext();

    return (
    <div className="Home">
      {isAuthenticated
            ? <OpenGate />
            : <div className="lander">
                <h1>Gate Control</h1>
                <p>Remote control of an automated gate</p>
              </div>
      }
    </div>
  );
}
