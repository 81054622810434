export default {
  "UserPoolClientId": "52fat665lsl5gn10et3qj76qq3",
  "PingLambdaFunctionQualifiedArn": "arn:aws:lambda:eu-west-1:424252610202:function:GateControl-prod-ping:13",
  "UserPoolDomain": "gatecontrol-prod",
  "IdentityPoolRegion": "eu-west-1",
  "UserPoolId": "eu-west-1_NysceA7A6",
  "IdentityPoolId": "eu-west-1:3599fa0c-d56d-4d72-9337-e2dadb1c18ae",
  "OpenLambdaFunctionQualifiedArn": "arn:aws:lambda:eu-west-1:424252610202:function:GateControl-prod-open:13",
  "StaticSiteDomain": "dzri1fyd3ip3w.cloudfront.net",
  "ServiceEndpoint": "https://prmre02haj.execute-api.eu-west-1.amazonaws.com/prod",
  "ServerlessDeploymentBucketName": "gatecontrol-prod-serverlessdeploymentbucket-6qvr36tuwwd0"
};