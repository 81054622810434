import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/context";
import { useFormFields } from "../libs/hooks";
import { onError } from "../libs/error";
import "./Signup.css";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);


  /**
   * Sign up form requires all fields to be populated to be submittable.
   */
  function validateForm() {
    return (
      fields.name.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }
  

  /**
   * Confirmation form requires the code to be entered to be valid.
   */
  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }


  /**
   * Submit new user to AWS Cognito.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          name: fields.name
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);

      // TODO: Check for the UsernameExistsException in the handleSubmit function’s catch block.
      //       Use the Auth.resendSignUp() method to resend the code if the user has not been previously confirmed. Here is a link to the Amplify API docs.
      //       Confirm the code just as we did before.
    }
  }
  

  /**
   * Submit verification code to AWS Cognito.
   */
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  

  /**
   * Rendering for the confirmation form.
   */
  function renderConfirmationForm() {
    return (
      <div class="container">
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
      <p>If you have any problems, please contact Andrew Flegg.</p>
      </div>
    );
  }


  /**
   * Rendering for the sign-up form.
   */
  function renderSignupForm() {
    return (
      <div class="container">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="name" bsSize="large">
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.name}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
      <p>Passwords must be at least 8 characters, including a number,
         an uppercase letter, a lowercase letter and a special character.</p>
      </div>
    );
  }

  
  /**
   * Toggle displayed form, based on state.
   */
  return (
    <div className="Signup">
      {newUser === null ? renderSignupForm() : renderConfirmationForm()}
    </div>
  );
}
