import React, { useState } from "react";
import { onError } from "../libs/error";
import { Storage } from "aws-amplify";
import "./S3CarInfo.css";

export default function S3CarInfo({
  bucket,
  object,
  ...props
}) {
  const [url, setUrl] = useState(null);

  /**
   * Get a URL on demand.
   */
  async function fetchUrl() {
    if (url === null) {
      try {
        if (object.endsWith('.jpg'))
          object += '.alpr.json';

        await Storage.get(object, { expires: 120, download: false })
                    .then(result => {
                      setUrl(result);
                      window.open(result, "alpr-info");
                    })
                    .catch(err => onError(err));
      } catch(e) {
        onError(e);
      }
      
    } else {
      window.open(url, "alpr-info");
    }
  }


  /**
   * Render the data.
   */
  return (
    // eslint-disable-next-line
    <a className="S3CarInfo" onClick={fetchUrl} href="#">[Info]</a>
  );
}
