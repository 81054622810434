import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/context";
import { onError } from "../libs/error";

export default function Guest() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();

  /**
   * On loading the component, hook up with AWS Cognito.
   */
  useEffect(() => {  
    async function onLoad() {
      console.log("Authenticating... " + window.location.search);
      var params = new URLSearchParams(window.location.search);
      var uid = params.get("u");
      var pwd = "@" + btoa(uid);

      try {
        await Auth.signIn(uid + "@gate.invalid", pwd);
        userHasAuthenticated(true);
      } catch (e) {
        onError(e);
      }
      history.push("/");
    }
    onLoad();
  }, [history, userHasAuthenticated]);

  return null;
}
